import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import { TableContainer, Table, TableTitle } from 'components/FormTables';
import AddIcon from '@mui/icons-material/Add';
import SpeedDialMenu from 'components/SpeedDialMenu';
import { GlobalContext } from 'store/context/GlobalContext';

export default function Page() {
    const context = useContext(GlobalContext);
    const { setSnack, setLoader } = context;
    const [ data, setData ] = useState({});
    const [ params, setParams ] = useState();

    const fakeData = {
        active_page: 1,
        countPerPage: 10,
        total_rows: 1,
        data: [
            {
                id: 1,
                title: "title1",
                content: "content1",
                to: "不限定",
                to_username: "",
                to_name: "",
                status: "1",
                created_at: "2021/06/08 15:10:31",
            },
            {
                id: 2,
                title: "title2",
                content: "content2",
                to: "限定",
                to_username: "seanchen676@gmail.com",
                to_name: "seanchen676",
                status: "0",
                created_at: "2021/06/07 15:10:31",
            },
        ]
    }

    const column = [
        {
            key: 'id',
            name: "#",
        },
        {
            key: 'status',
            name: "狀態",
            view: (data) => {
                return data.status === "1" ? <span className="text-success">上架</span> : <span className="text-danger">下架</span> ;
            }
        },
        {
            key: 'title',
            name: "推播標題",
        },
        {
            key: 'to',
            name: "發送對象",
        },
        {
            key: 'to_username',
            name: "限定發送帳號",
            view: (data) => {
                return data.to_username !== "" ? data.to_username : "-";
            }
        },
        {
            key: 'to_name',
            name: "限定發送姓名",
            view: (data) => {
                return data.to_name !== "" ? data.to_name : "-";
            }
        },
        {
            key: 'created_at',
            name: "發送推播時間",
        },
        {
            key: 'tools',
            name: '功能',
            view: (data) => (<>
                <div className="d-flex justify-content-center">
                    <Link to={`/application/Notification/edit/${data.id}`} className="common-btn table-btn black"><i className="fas fa-pen mr-1"></i>修改推播通知</Link>
                </div>
            </>)
        },
    ];

    const fetchData = useCallback(async (callbackParams) => {
        setLoader(true);
        // let result = await getAdminList(callbackParams ? callbackParams : params);
        // let reuslt = await fakeData;
        // setData(reuslt);
        setData({});
        setLoader(false);
    }, []);

    const handleCallback = (callbackParams) => {
        setParams(callbackParams);
        fetchData(callbackParams);
    }

    const actions = [
        { icon: <Link to={`/application/Notification/create`} className="d-flex justify-content-center align-items-center" style={{width: "40px", height: "40px"}}><AddIcon /></Link>, name: '新增推播通知'},
    ];

    return (<>
        <TableContainer>
            <Table
                column={column}
                data={data}
                checkbox={false}
                searchbar={{date: true, keyword: true}}
                defaultPerPage={10}
                callback={(callbackParams) => handleCallback(callbackParams)}
            />
        </TableContainer>
        <SpeedDialMenu menuList={actions} />
    </>)
}
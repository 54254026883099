import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import { GlobalContext } from 'store/context/GlobalContext';
import { TableContainer, Table, TableTitle } from 'components/FormTables';
import { CSVLink } from "react-csv";
import moment from 'moment/moment.js';
import AddIcon from '@mui/icons-material/Add';
import GetAppIcon from '@mui/icons-material/GetApp';

import { userList } from 'services/authService';
import SpeedDialMenu from 'components/SpeedDialMenu';
import TooltipButton from 'components/TooltipButton';

export default function List() {
    let mounted = true;
    const context = useContext(GlobalContext);
    const { setSnack, setLoader } = context;
    const [ data, setData ] = useState({});
    const [ params, setParams ] = useState();
    const [ exportData, setExportData ] = useState([]);

    useEffect(() => {
        return () => mounted = false;
    }, [])

    useEffect(() => {
        if(data.data !== undefined) {
            let result = [];
            data.data.map((item, key) => {
                result.push(
                    {
                        "#": item.id,
                        "邀請碼": item.invitation_token,
                        "會員帳號": item.phone,
                        "會員姓名": item.name,
                        "推薦人": item.parent_name,
                        "銀行驗證": item.bank_verified === "1" ? "已驗證" : "未驗證",
                        "身分證驗證": item.identity_verified === "1" ? "已驗證" : "未驗證",
                        "會員等級": item.role,
                        "註冊時間": moment(data.created_at).format("YYYY-MM-DD HH:mm:ss"),
                    }
                )
            })
            setExportData(result);
        }
    }, [data])

    const column = [
        {
            key: 'id',
            name: "#",
        },
        {
            key: 'username',
            name: "會員帳號",
        },
        {
            key: 'name',
            name: "會員姓名",
            order: true,
        },
        {
            key: 'email',
            name: "電子信箱",
            view: (data) => data.email ? data.email : '-'
        },
        {
            key: 'parent_username',
            name: "推薦人",
            view: (data) => {
                return data.parent_username ? <><div>{data.parent_name}</div><div>({data.parent_username})</div></> : "-";
            }
        },
        {
            key: 'invitation_token',
            name: "邀請碼",
        },
        {
            key: 'created_at',
            name: "註冊時間",
            view: (data) => {
                return moment(data.created_at).format("YYYY-MM-DD HH:mm:ss");
            }
        },
        {
            key: 'tools',
            name: '功能',
            view: (data) => (<>
                <div className="d-flex justify-content-center">
                    <Link to={`/member/List/edit/${data.id}`} className="common-btn table-btn black"><i className="fas fa-pen mr-1"></i>修改</Link>
                </div>
            </>)
        },
    ];

    const fetchData = useCallback(async (callbackParams) => {
        setLoader(true);
        if(mounted){
            let result = await userList(callbackParams ? callbackParams : params);
            setData(result);
            setLoader(false);
        }
    }, [mounted]);

    const handleCallback = (callbackParams) => {
        setParams(callbackParams);
        fetchData(callbackParams);
    }

    const actions = [
        { icon: <Link to="/member/List/create" className="d-flex justify-content-center align-items-center" style={{width: "40px", height: "40px"}}><AddIcon /></Link>, name: '新增會員'},
        // { icon: <CSVLink filename="會員列表.csv" data={exportData} className="d-flex justify-content-center align-items-center" style={{width: "40px", height: "40px"}}><GetAppIcon /></CSVLink>, name: '匯出資料'},
    ];

    return (<>
        <TableContainer>
            <Table
                column={column}
                data={data}
                checkbox={{
                    open: true,
                    tools: <>
                        <TooltipButton title="刪除會員" placement="bottom">
                            <button type="button" className="tooltip-button font-size-18"><i className="fas fa-trash"></i></button>
                        </TooltipButton>
                    </>
                }}
                maxWidth="unset"
                searchbar={{date: false, keyword: true}}
                defaultPerPage={10}
                callback={(callbackParams) => handleCallback(callbackParams)}
            />
        </TableContainer>
        <SpeedDialMenu menuList={actions} />
    </>)
}
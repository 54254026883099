import React, { useContext, useEffect, useRef } from 'react';
import { GlobalContext } from 'store/context/GlobalContext';
import WrapperContainer, { WrapperHeader, WrapperTitle, WrapperContent, WrapperBreadcrumb } from './Wrapper';
import TransitionGroupFade from 'components/Transition';

const Content = (props) => {
    const context = useContext(GlobalContext);
    const { children } = props;
    const wrapperEl = useRef(null);

    const menuClose = () => {
        window.innerWidth < 992 && context.setMenuToggle(false);
    }

    return (
        <main className={context.menuToggle ? ' active' : ''} ref={wrapperEl} onClick={menuClose}>
            <TransitionGroupFade>
                <WrapperContainer className="fit-content">
                    <WrapperHeader className="sticky-fit-width sticky-left">
                        <WrapperTitle>{context.pageState.title}</WrapperTitle>
                        <WrapperBreadcrumb>{context.pageState.breadcrumb.map((item, key) => <li className={`breadcrumb-item ${key !== 0 ? "active" : ""}`} key={key}>{item.title}</li>)}</WrapperBreadcrumb>
                    </WrapperHeader>
                    <WrapperContent>
                        { children }
                    </WrapperContent>
                </WrapperContainer>
            </TransitionGroupFade>
        </main>
    );
}

export default Content;

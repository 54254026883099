import React from 'react';

const OnePageShow = (props) => {
    const { perPageCallback, defaultPerPage } = props;

    return (
        <div className="mb-4 font-size-sm">
            一頁顯示
            <select
                className="onepageshow-select mx-1"
                defaultValue={defaultPerPage ? defaultPerPage : 10}
                onChange={(e) => perPageCallback(e.target.value)}
            >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
            筆資料
        </div>
    )
}

export default OnePageShow;

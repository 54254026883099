import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from 'store/context/GlobalContext';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Content from './components/Content';
import { fetchUser } from 'services/authService';

export default function Private({ children }) {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state) => state.Auth.isAuthenticated);
    const user = useSelector((state) => state.Auth.user);
    let history = useHistory();
    const context = useContext(GlobalContext);
    const { pageState } = context;
    const { role } = pageState;

    useEffect(() => {
		if (isAuthenticated && !user.username) {
			dispatch(fetchUser());
		}
    }, [isAuthenticated]);

    useEffect(() => {
        if (Object.keys(user).length === 0) return;
        if(role && !role.includes(user.role)) history.goBack();
    }, [role]);
    
    return (<>
        <Header />
        <div className="user-wrapper">
            <Sidebar />
            <Content>
                { children }
            </Content>
        </div>
    </>)
}
import React from 'react';
import Checkbox from '@mui/material/Checkbox';

const Tbody = ({ column, data, checkbox, checkboxArray, checkboxCallback, childInfo }) => {
    let columnCount = column.length;
    if(checkbox) columnCount += 1;

    const handleCheckbox = (id) => {
        let index = checkboxArray.indexOf(id);
        return index> -1 ? true : false;
    }

    return (<tbody>
        {
            data &&
            data.map((item, k) => {
                return (
                    <tr key={k}>
                        { checkbox.open && (
                            <td className="table-border table-border-right sticky-top sticky-left" style={{minWidth: '70px', width: '70px', zIndex: "1"}}>
                                <Checkbox
                                    sx={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}
                                    checked={handleCheckbox(k)}
                                    onChange={(e) => checkboxCallback({toggle: e.target.checked, key: k})}
                                />
                            </td>
                        )}
                        { column.map((field, key) => {
                            const dataValue = item[field['key']];

                            if(field['view']) {
                                return (<td key={key}>{field.view(item)}</td>);
                            }

                            if(dataValue) {
                                return (<td key={key}>{dataValue}</td>);
                            }

                            return (<td key={key}></td>);
                        }) }
                    </tr>
                )
            })
        }
    </tbody>);
}

export default Tbody;
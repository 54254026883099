import Login from 'pages/Auth/Login';

import Dashboard from 'pages/Dashboard';

// System----------------------------------------------
import SystemAdministrator from 'pages/System/Administrator';
import SystemAdministratorCreate from 'pages/System/Administrator/Create';
import SystemAdministratorEdit from 'pages/System/Administrator/Edit';
import SystemAdministratorProfile from 'pages/System/Administrator/Profile';

// Member----------------------------------------------
import MemberList from 'pages/Member/List';
import MemberListEdit from 'pages/Member/List/Edit';
import MemberListCreate from 'pages/Member/List/Create';

import MemberMail from 'pages/Member/Mail';
import MemberSocial from 'pages/Member/Social';

// Plan----------------------------------------------
import PlanList from 'pages/Plan/List';
import PlanPurchase from 'pages/Plan/Purchase';
import PlanUnsubscribe from 'pages/Plan/Unsubscribe';
import PlanRefund from 'pages/Plan/Refund';

// Social----------------------------------------------
import SocialContact from 'pages/Social/Contact';

// Faq----------------------------------------------
import FaqList from 'pages/Faq/List';
import FaqCategory from 'pages/Faq/Category';

// Application----------------------------------------------
import ApplicationNews from 'pages/Application/News';
import ApplicationNewsCreate from 'pages/Application/News/Create';
import ApplicationNewsEdit from 'pages/Application/News/Edit';
import ApplicationNotification from 'pages/Application/Notification';
import ApplicationNotificationCreate from 'pages/Application/Notification/Create';
import ApplicationNotificationEdit from 'pages/Application/Notification/Edit';

const routes = [
    {
        path: '/',
        exact: true,
        auth: false,
        component: Login,
        title: '登入',
    },
    {
        path: '/login',
        exact: true,
        auth: false,
        component: Login,
        title: '登入',
    },
    {
        path: '/dashboard',
        exact: true,
        auth: true,
        component: Dashboard,
        title: '儀表板',
        hasMain: true,
    },
    {
        path: '/system',
        exact: true,
        auth: true,
        component: SystemAdministrator,
        title: '系統管理',
        routes: [
            {
                path: '/Administrator',
                exact: true,
                auth: true,
                component: SystemAdministrator,
                title: '管理員列表',
                hasMain: true,
                routes: [
                    {
                        path: '/create',
                        exact: true,
                        auth: true,
                        component: SystemAdministratorCreate,
                        title: '新增管理員',
                        role: ['developer', 'super'],
                    },
                    {
                        path: '/profile',
                        exact: true,
                        auth: true,
                        component: SystemAdministratorProfile,
                        title: '編輯管理員',
                    },
                    {
                        path: '/edit/:id',
                        exact: true,
                        auth: true,
                        component: SystemAdministratorEdit,
                        title: '編輯管理員',
                        role: ['developer', 'super'],
                    },
                ]
            },
        ]
    },
    {
        path: '/member',
        exact: true,
        auth: true,
        component: MemberList,
        title: '會員管理',
        hasMain: true,
        routes: [
            {
                path: '/List',
                exact: true,
                auth: true,
                component: MemberList,
                title: '會員列表',
                hasMain: true,
                routes: [
                    {
                        path: '/create',
                        exact: true,
                        auth: true,
                        component: MemberListCreate,
                        title: '新增會員',
                    },
                    {
                        path: '/edit/:id',
                        exact: true,
                        auth: true,
                        component: MemberListEdit,
                        title: '編輯會員資料',
                    },
                ]
            },
            {
                path: '/Mail',
                exact: true,
                auth: true,
                component: MemberMail,
                title: '信箱綁定',
                hasMain: true,
            },
            {
                path: '/Social',
                exact: true,
                auth: true,
                component: MemberSocial,
                title: '社群帳號綁定',
                hasMain: true,
            },
        ]
    },
    {
        path: '/plan',
        exact: true,
        auth: true,
        component: PlanList,
        title: '方案管理',
        hasMain: true,
        routes: [
            {
                path: '/List',
                exact: true,
                auth: true,
                component: PlanList,
                title: '會員方案列表',
                hasMain: true,
            },
            {
                path: '/Purchase',
                exact: true,
                auth: true,
                component: PlanPurchase,
                title: '方案購買紀錄',
                hasMain: true,
            },
            {
                path: '/Unsubscribe',
                exact: true,
                auth: true,
                component: PlanUnsubscribe,
                title: '取消續訂紀錄',
                hasMain: true,
            },
            {
                path: '/Refund',
                exact: true,
                auth: true,
                component: PlanRefund,
                title: '退款申請',
                hasMain: true,
            },
        ]
    },
    {
        path: '/social',
        exact: true,
        auth: true,
        component: SocialContact,
        title: '社群管理',
        hasMain: true,
        routes: [
            {
                path: '/Contact',
                exact: true,
                auth: true,
                component: SocialContact,
                title: '聯絡我們',
                hasMain: true,
            },
        ]
    },
    {
        path: '/faq',
        exact: true,
        auth: true,
        component: FaqList,
        title: '常見問題管理',
        hasMain: true,
        routes: [
            {
                path: '/List',
                exact: true,
                auth: true,
                component: FaqList,
                title: '常見問題列表',
                hasMain: true,
            },
            {
                path: '/Category',
                exact: true,
                auth: true,
                component: FaqCategory,
                title: '常見問題分類',
                hasMain: true,
            },
        ]
    },
    {
        path: '/application',
        exact: true,
        auth: true,
        component: ApplicationNews,
        title: '應用管理',
        hasMain: true,
        routes: [
            {
                path: '/News',
                exact: true,
                auth: true,
                component: ApplicationNews,
                title: '最新公告',
                hasMain: true,
                routes: [
                    {
                        path: '/create',
                        exact: true,
                        auth: true,
                        component: ApplicationNewsCreate,
                        title: '新增最新公告',
                    },
                ]
            },
            {
                path: '/Notification',
                exact: true,
                auth: true,
                component: ApplicationNotification,
                title: '推播通知',
                hasMain: true,
            },
        ]
    },
];

export default routes;
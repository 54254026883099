import Http from "utils/Http";
import * as action from "store/actions";

let BASE_API_URL;
let API_URL;

if(process.env.REACT_APP_ENVIRONMENT === 'development') {
	BASE_API_URL = process.env.REACT_APP_TEST_BASE_API_URL;
	API_URL = process.env.REACT_APP_TEST_API_URL;
} else {
	BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
	API_URL = process.env.REACT_APP_API_URL;
}

export function login(credentials) {
	return dispatch =>
		new Promise((resolve, reject) => {
			Http.post(API_URL+"/auth/login", credentials)
				.then(res => {
					dispatch(action.authLogin(res.data));
					return resolve(res.data);
				})
				.catch(err => {
					const statusCode = err.response.status;
					if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
						reject(err.response.data);
					}
				});
		});
}

export function refreshToken() {
	let credentials = {
		grant_type: "refresh_token",
		refresh_token: localStorage.getItem('refresh_token')
	}
	return dispatch =>
		new Promise((resolve, reject) => {
			Http.post(API_URL+"/auth/refreshToken", credentials)
				.then(res => {
					dispatch(action.authRefreshToken(res.data));
					return resolve(res.data);
				})
				.catch(err => {
					const statusCode = err.response.status;
					if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
						reject(err.response.data);
					}
				});
		});
}

export function logout() {
	return dispatch =>
		new Promise((resolve, reject) => {
			Http.post(API_URL+"/logout")
				.then(res => {
					dispatch(action.authLogout());
					return resolve(res.data);
				})
				.catch(err => {
					const statusCode = err.response.status;
					if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
						reject(err.response.data);
					}
				});
		});
}

export function fetchUser() {
	return dispatch =>
		new Promise((resolve, reject) => {
			Http.get(API_URL+"/administrator/profile")
				.then(res => {
					dispatch(action.authUser(res.data));
					return resolve();
				})
				.catch(err => {
					const statusCode = err.response.status;
					if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
						reject(err);
					}
				});
		});
}

//儀表板---------------------------------------------------------------------------------------
export function getDashboard() {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/auth/dashboard")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getBidsCount() {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/counts/bids")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getUsersCount() {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/counts/users")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getWalletCoinCount() {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/counts/wallets/coin")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getWalletBonusCount() {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/counts/wallets/bonus")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getWithdrawCount() {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/counts/withdraws")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getSmsCount() {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/counts/sms")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//-------------------------------------------------------------------------------------------

//管理員列表 Administrator---------------------------------------------------------------------------------------
export function getAdminList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/administrator", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getAdminProfile(id) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/administrator/profile/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function createAdmin(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/administrator", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateAdminProfile(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/administrator/profile", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateAdmin(id, credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/administrator/profile/"+id, credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function deleteAdmin(id) {
	return new Promise((resolve, reject) => {
		Http.delete(API_URL+"/administrator/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//--------------------------------------------------------------------------------------------------

//會員列表---------------------------------------------------------------------------------------
export function userList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/users", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getRoleList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/users/role", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function createUser(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/users", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getUserItem(id) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/users/"+ id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getUserTeam(id) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/users/team/"+ id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateUser(id, credentials) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/users/"+id, credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
export function searchUser(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/users/search", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//--------------------------------------------------------------------------------------------------

//信箱管理---------------------------------------------------------------------------------------
export function userEmailList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/users/email", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getUserEmail(id) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/users/email/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateUserEmail(id, credentials) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/users/email/"+id, credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//--------------------------------------------------------------------------------------------------


//身份管理---------------------------------------------------------------------------------------
export function userIDCardList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/identity/idcard", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getUserIDCard(id) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/identity/idcard/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateUserIDCard(id, credentials) {
	const config = {
		headers: {'Content-Type': 'application/x-www-form-urlencoded'}
	};

	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/identity/idcard/"+id, credentials, config)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function verifyIDCard(id, credentials) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/identity/idcard/verify/"+id, credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//--------------------------------------------------------------------------------------------------

//銀行帳戶管理---------------------------------------------------------------------------------------
export function userBankList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/payments/bank", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getUserBank(id) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/payments/bank/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateUserBank(id, credentials) {
	const config = {
		headers: {'Content-Type': 'application/x-www-form-urlencoded'}
	};

	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/payments/bank/"+id, credentials, config)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function verifyBank(id, credentials) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/payments/bank/verify/"+id, credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//--------------------------------------------------------------------------------------------------

//電子錢包管理---------------------------------------------------------------------------------------
export function userBlockChainList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/payments/blockchain", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getUserBlockChain(id) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/payments/blockchain/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateUserBlockChain(id, credentials) {

	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/payments/blockchain/"+id, credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function verifyBlockChain(id, credentials) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/payments/blockchain/verify/"+id, credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//--------------------------------------------------------------------------------------------------

//會員資產管理---------------------------------------------------------------------------------------
export function getUserWallet(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/wallet", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//---------------------------------------------------------------------------------------------------

//餘額、獎金、點數發送 transfer---------------------------------------------------------------------------------------
export function getTransactionList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/wallet/transaction", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function transactionSend(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/wallet/transaction", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//---------------------------------------------------------------------------------------------------

//會員餘額紀錄 memberCoin------------------------------------------------------------------------------
export function getWalletCoin(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/wallet/coin", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//---------------------------------------------------------------------------------------------------

//會員獎金紀錄 memberBonus------------------------------------------------------------------------------
export function getWalletBonus(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/wallet/bonus", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//---------------------------------------------------------------------------------------------------

//會員點數紀錄 memberPoint------------------------------------------------------------------------------
export function getWalletPoint(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/wallet/point", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//---------------------------------------------------------------------------------------------------

//兌換紀錄 financeExchange------------------------------------------------------------------------------
export function getWalletExchange(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/wallet/exchange", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//---------------------------------------------------------------------------------------------------

//提領申請紀錄 financeWithdraw------------------------------------------------------------------------------
export function getWalletWithdraw(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/wallet/withdraw", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function walletWithdrawStatus(id, type) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/wallet/withdraw/"+id+"/"+type)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//---------------------------------------------------------------------------------------------------

//標會列表---------------------------------------------------------------------------------------
export function bidList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/bids", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function createBid(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/bids", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function deleteBid(id) {
	return new Promise((resolve, reject) => {
		Http.delete(API_URL+"/bids/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getBidDetail(id) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/bids/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function bidPublish(id) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/bids/publish/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function bidClose(id) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/bids/close/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function BidItemPaymentList(id, credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/bids/items/"+id+"/payments", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function BidAwardPaymentConfirm(id, credentials) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/bids/awards/"+id+"/payment/confirm", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function BidAwardPaymentCancel(id) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/bids/awards/"+id+"/payment/cancel")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getBidItemAwardUserList(id) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/bids/items/"+id+"/notAwardUsers")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function BidItemAwardSubmit(id, credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/bids/items/"+id+"/awards", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function BidUserPremierStatus(id, credentials) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/bids/users/premier/"+id, credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function BidPaymentVerify(id, credentials) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/bids/payments/"+id+"/verify", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function BidPaymentNotification(id) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/bids/payments/"+id+"/notification")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//---------------------------------------------------------------------------------------------------

//所有標會得標紀錄 bidAward------------------------------------------------------------------------------
export function getBidAward(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/bids/awards", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//---------------------------------------------------------------------------------------------------

//所有標會繳款紀錄 bidPayment------------------------------------------------------------------------------
export function getBidsPayment(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/bids/payments", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//---------------------------------------------------------------------------------------------------

//消息管理---------------------------------------------------------------------------------------
export function getNewsList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/news", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getNewsCategory(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/news/category", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getNewsItem(id) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/news/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function createNews(credentials) {
	const config = {
		headers: {'Content-Type': 'application/x-www-form-urlencoded'}
	};

	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/news", credentials, config)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateNews(id, credentials) {
	const config = {
		headers: {'Content-Type': 'application/x-www-form-urlencoded'}
	};

	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/news/"+id, credentials, config)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateNewsStatus(id) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/news/status/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function deleteNews(id) {
	return new Promise((resolve, reject) => {
		Http.delete(API_URL+"/news/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//--------------------------------------------------------------------------------------------------

//廣告輪播管理---------------------------------------------------------------------------------------
export function getBannerList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/banner", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getBannerItem(id) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/banner/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function createBanner(credentials) {
	const config = {
		headers: {'Content-Type': 'application/x-www-form-urlencoded'}
	};

	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/banner", credentials, config)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateBanner(id, credentials) {
	const config = {
		headers: {'Content-Type': 'application/x-www-form-urlencoded'}
	};

	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/banner/"+id, credentials, config)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateBannerStatus(id) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/banner/status/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function deleteBanner(id) {
	return new Promise((resolve, reject) => {
		Http.delete(API_URL+"/banner/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//--------------------------------------------------------------------------------------------------

//新手指南---------------------------------------------------------------------------------------
export function getUserGuide() {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/guide")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateUserGuide(credentials) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/guide", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//--------------------------------------------------------------------------------------------------

//制式標會合約內容---------------------------------------------------------------------------------------
export function getBidContract() {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/contract/bid")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateBidContract(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/contract/bid", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//--------------------------------------------------------------------------------------------------

//關於我們---------------------------------------------------------------------------------------
export function getAbout() {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/about")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateAbout(credentials) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/about", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//--------------------------------------------------------------------------------------------------

//公司帳戶---------------------------------------------------------------------------------------
export function getCompanyAccounts(type) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/company/accounts/"+type)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateCompanyBank(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/company/accounts/bank", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateCompanyBlockchain(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/company/accounts/blockchain",credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//--------------------------------------------------------------------------------------------------

//簡訊管理 Sms---------------------------------------------------------------------------------------
export function getSmsList() {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/sms")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function createSmsPoint(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/sms",credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

// export function updateCompanyBank(credentials) {
// 	return new Promise((resolve, reject) => {
// 		Http.post(API_URL+"/company/accounts/bank", credentials)
// 			.then(response => {
// 				resolve(response.data);
// 			})
// 			.catch(err => {
// 				const statusCode = err.response.status;
// 				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
// 					reject(err.response.data);
// 				}
// 			});
// 	});
// }

// export function updateCompanyBlockchain(credentials) {
// 	return new Promise((resolve, reject) => {
// 		Http.post(API_URL+"/company/accounts/blockchain",credentials)
// 			.then(response => {
// 				resolve(response.data);
// 			})
// 			.catch(err => {
// 				const statusCode = err.response.status;
// 				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
// 					reject(err.response.data);
// 				}
// 			});
// 	});
// }
//--------------------------------------------------------------------------------------------------
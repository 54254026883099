import React, { useState, useEffect, useCallback, useContext } from 'react';
import useForm from "react-hook-form";
import Upload from "components/Upload";
import Editor from 'components/Editor';
import { FormGroup, InputGroup, SwitchGroup } from 'components/FormGroup';
import { createNews, getNewsCategory } from 'services/authService';
import ConfirmButton from 'components/ConfirmButton';
import { GlobalContext } from 'store/context/GlobalContext';

export default function Create(props) {
    let mounted = true;
    const context = useContext(GlobalContext);
    const { setSnack, setLoader } = context;
    const { register, handleSubmit, getValues, reset, setValue } = useForm();
    const [ showToggle, setShowToggle ] = useState(true);
    const [ previewImage, setPreviewImage ] = useState({});
    const [ imageClear, setImageClear ] = useState(false);
    const [ editorData, setEditorData ] = useState("");
    const [ categoryData, setCategoryData ] = useState([]);
    const [ category, setCategory ] = useState(1);

    const fetchCategory = useCallback(async() => {
        setLoader(true);
        let result = await getNewsCategory();
        if(mounted){
            setCategoryData(result.data);
            setLoader(false);
        }
    }, [mounted])

    useEffect(() => {
        fetchCategory();
        return () => {
            mounted = false;
        }
    }, [fetchCategory])

    const onSubmit = () => {
        let values = getValues();

        let result = {...values, status: showToggle ? "publish" : "closed", content: editorData, news_category_id: category};

        const formData = new FormData();
		Object.keys(result).map(function(key, index) {
            if(key === 'image') return formData.append(key, result.image[0]);
			return formData.append(key, result[key]);
        });

        setLoader(true);
        createNews(formData).then(res => {
            setSnack({open: true, text: res.messages});
            reset();
            setImageClear(true);
            setTimeout(() => setImageClear(false), 0);
            setValue("image", "");
            setEditorData("");
            setLoader(false);
        }).catch(err => {
            setSnack({open: true, text: err.messages.error});
            setLoader(false);
        })
    }

    const hanleValidate = () => {
        let values = getValues();
        if(values.title === ""){
            setSnack({open: true, text: "請輸入標題"});
            return false;
        }
        if(values.desc === ""){
            setSnack({open: true, text: "請輸入簡介"});
            return false;
        }
        if(editorData === ""){
            setSnack({open: true, text: "請輸入內文"});
            return false;
        }
        if(values.image.length === 0){
            setSnack({open: true, text: "請上傳縮圖"});
            return false;
        }
        return true;
    }

    return (
        <div className="tools-tags-create-page">
            <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-container">
                    <FormGroup label="上下架">
                        <SwitchGroup>
                            <label className="switch" >
                                <input type="checkbox" checked={showToggle} onChange={() => setShowToggle(!showToggle)}/>
                                <span className="slider round"></span>
                            </label>
                        </SwitchGroup>
                    </FormGroup>
                    <FormGroup label="標題" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="title"
                                type="text"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入標題"
                                ref={register}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="簡介" required>
                        <InputGroup>
                            <textarea
                                tabIndex="1"
                                rows="3"
                                name="desc"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入簡介"
                                ref={register}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="內文" required>
                        <Editor name="content" data={editorData} callback={(data) => setEditorData(data)} />
                    </FormGroup>
                    <FormGroup label="上傳縮圖" required>
                        <Upload
                            id="image"
                            maxWidth="auto"
                            maxHeight="200px"
                            name="image"
                            valid={{type: ["image/jpeg", "image/png"]}}
                            register={register}
                            clearCallback={() => setValue("image", "")}
                            callback={(e) => setPreviewImage(e)}
                            clear={imageClear}
                        />
                        <div className="font-size-xs text-danger">圖檔長寬比建議為1:1</div>
                    </FormGroup>
                    {
                        category == 2 &&
                        <FormGroup label="已預約人數" required>
                            <InputGroup>
                                <input
                                    tabIndex="1"
                                    name="count"
                                    type="text"
                                    spellCheck="false"
                                    autoComplete="off"
                                    placeholder="請輸入已預約人數"
                                    ref={register}
                                />
                            </InputGroup>
                            <div className="font-size-xs text-danger pt-1">格式為 目前預約人數/總共可預約人數 (EX：10/24)</div>
                        </FormGroup>
                    }
                </div>
                <div className="text-right">
                    <ConfirmButton
                        className="submit-btn"
                        onValidate={hanleValidate}
                        onSuccess={onSubmit}
                        confirmText="確定要送出嗎？"
                        confirm={{show: true, text: '送出'}}
                        cancel={{show: true}}
                    />
                </div>
            </form>
        </div>
    )
}
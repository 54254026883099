import React, { useEffect, useState, useCallback, useContext } from 'react';
import useForm from "react-hook-form";
import { GlobalContext } from 'store/context/GlobalContext';
import { FormGroup, InputGroup, TextGroup } from 'components/FormGroup';
import { createUser, searchUser as searchUserAPI } from 'services/authService';
import ConfirmButton from 'components/ConfirmButton';
import SearchFilter from 'components/Form/SearchFilter';

export default function Create(props) {
    let mounted = true;
    const context = useContext(GlobalContext);
    const { setSnack, setLoader } = context;
    const { register, handleSubmit, getValues, reset, setValue } = useForm();
    const [ passwordShow, setPasswordShow ] = useState(false);
    const [ repasswordShow, setRepasswordShow ] = useState(false);
    const [ searchData, setSearchData ] = useState([]);
    const [ selectUser, setSelectUser ] = useState({id: '', name: '', invitation_token: ''});
    const [ clearForm, setClearForm ] = useState(false);

    const handleSearch = useCallback(async() => {
        mounted = true;
        let result = await searchUserAPI();

        if(mounted){
            if(!result) return;
            let data = [];
            result.data.map((item, index) => {
                let inputName = `${item.phone} (${item.name})`;
                return data = [...data, {...item, lable: inputName}]
            })
            setSearchData(data);
        }
    }, [])

    useEffect(() => {
        handleSearch();
        return () => mounted = false;
    }, [handleSearch])

    const onSubmit = () => {
        let values = getValues();
        let result = {...values, invitation_token: selectUser.invitation_token};

        setLoader(true);
        createUser(result).then(res => {
            setSnack({open: true, text: res.messages});
            reset();
            setSelectUser({id: '', name: '', invitation_token: ''});
            setClearForm(true);
            handleSearch();
            setTimeout(() => {
                setClearForm(false);
            }, 0)
            setLoader(false);
        }).catch(err => {
            setSnack({open: true, text: err.messages.error});
            setLoader(false);
        })
    }

    const hanleValidate = () => {
        let values = getValues();
        if(values.name === ""){
            setSnack({open: true, text: "請輸入真實姓名"});
            return false;
        }
        if(values.password === ""){
            setSnack({open: true, text: "請輸入登入密碼"});
            return false;
        }
        if(values.password_confirmation === ""){
            setSnack({open: true, text: "請輸入確認密碼"});
            return false;
        }
        if(values.password !== "" && values.password_confirmation !== ""){
            if(values.password_confirmation !== values.password){
                setSnack({open: true, text: "確認密碼不一致"});
                return false;
            }
        }
        if(values.phone === ""){
            setSnack({open: true, text: "請輸入電話號碼"});
            return false;
        }
        if(selectUser.invitation_token === ""){
            setSnack({open: true, text: "未匹配到正確的邀請人帳號"});
            return false;
        }

        return true;
    }

    return (
        <div className="tools-tags-create-page">
            <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-container">
                    <FormGroup label="真實姓名" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="name"
                                type="text"
                                spellCheck="false"
                                autoComplete="new-username"
                                placeholder="請輸入真實姓名"
                                ref={register}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="登入密碼" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="password"
                                type={passwordShow ? "text" : "password"}
                                spellCheck="false"
                                autoComplete="new-password"
                                placeholder="請輸入登入密碼"
                                ref={register}
                            />
                            <div className="input-text">
                                <button type="button" className="view-pass-btn" onClick={() => setPasswordShow(!passwordShow)}>
                                    {passwordShow ? (<i className="far fa-eye"></i>) : (<i className="far fa-eye-slash text-secondary"></i>)}
                                </button>
                            </div>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="確認密碼" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="password_confirmation"
                                type={repasswordShow ? "text" : "password"}
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入確認密碼"
                                ref={register}
                            />
                            <div className="input-text">
                                <button type="button" className="view-pass-btn" onClick={() => setRepasswordShow(!repasswordShow)}>
                                    {repasswordShow ?  (<i className="far fa-eye"></i>) : (<i className="far fa-eye-slash text-secondary"></i>)}
                                </button>
                            </div>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="電話號碼" required>
                        <div className="d-flex">
                            <select
                                name="phone_code"
                                className="mr-1"
                                defaultValue="+886"
                                style={{maxWidth: " 90px"}}
                                ref={register}
                            >
                                <option value="+886">+886</option>
                            </select>
                            <InputGroup>
                                <input
                                    tabIndex="1"
                                    name="phone"
                                    type="tel"
                                    spellCheck="false"
                                    autoComplete="off"
                                    placeholder="請輸入電話號碼"
                                    ref={register}
                                />
                            </InputGroup>
                        </div>
                    </FormGroup>
                    <FormGroup label="邀請人帳號" required>
                        <SearchFilter
                            placeholder="請輸入邀請人帳號"
                            data={searchData}
                            callback={(value) => setSelectUser(value)}
                            clearForm={clearForm}
                        />
                    </FormGroup>
                    <FormGroup label="邀請人姓名">
                        <TextGroup>
                            { selectUser && (selectUser.name && selectUser.name !== "") ? selectUser.name : "( 系統自動帶入, 請輸入上方邀請人帳號 )" }
                        </TextGroup>
                    </FormGroup>
                    <FormGroup label="邀請碼">
                        <TextGroup>
                            { selectUser && (selectUser.invitation_token && selectUser.invitation_token !== "") ? selectUser.invitation_token : "( 系統自動帶入, 請輸入上方邀請人帳號 )" }
                        </TextGroup>
                    </FormGroup>
                </div>
                <div className="text-right">
                    <ConfirmButton
                        className="submit-btn"
                        onValidate={hanleValidate}
                        onSuccess={onSubmit}
                        confirmText="確定要送出嗎？"
                        confirm={{show: true, text: '送出'}}
                        cancel={{show: true}}
                    />
                </div>
            </form>
        </div>
    )
}
import React, { useState, useEffect, useCallback, useContext } from 'react';
import useForm from "react-hook-form";
import { useParams } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FormGroup, InputGroup, TextGroup } from 'components/FormGroup';
import ConfirmButton from 'components/ConfirmButton';
import { getUserItem, updateUser, searchUser as searchUserAPI, getRoleList } from "services/authService";
import { GlobalContext } from 'store/context/GlobalContext';
import SearchFilter from 'components/Form/SearchFilter';

export default function Create(props) {
    let mounted = true;
    const { id } = useParams();
    const context = useContext(GlobalContext);
    const { setSnack, setLoader } = context;
    const { register, handleSubmit, getValues, reset, setValue } = useForm();
    const [ passwordShow, setPasswordShow ] = useState(false);
    const [ repasswordShow, setRepasswordShow ] = useState(false);
    const [ data, setData ] = useState({});
    const [ roleList, setRoleList ] = useState([]);
    const [ searchData, setSearchData ] = useState([]);
    const [ selectUser, setSelectUser ] = useState({id: '', name: '', invitation_token: ''});
    const [ clearForm, setClearForm ] = useState(false);

    const handleSearch = useCallback(async() => {
        mounted = true;
        let result = await searchUserAPI();

        if(mounted){
            if(!result) return;
            let data = [];
            result.data.map((item, index) => {
                let inputName = `${item.phone} (${item.name})`;
                return data = [...data, {...item, lable: inputName}]
            })
            setSearchData(data);
        }
    }, [])

    const fetchRole = useCallback(async () => {
        setLoader(true);
        let result = await getRoleList();
        if(mounted) {
            setRoleList(result.data);
        }
        setLoader(false);
    }, [])

    const fetchData = useCallback(async () => {
        setLoader(true);
        let result = await getUserItem(id);
        if(mounted) {
            setData(result.data);
        }
        setLoader(false);
    }, [])

    useEffect(() => {
        fetchData();
        handleSearch();
        fetchRole();
        return () => {
            mounted = false;
        }
    }, [fetchData, handleSearch, fetchRole])

    const onSubmit = () => {
        let values = getValues();
        let result = {...values};
        if(selectUser.invitation_token !== ''){
            result = {...result, invitation_token: selectUser.invitation_token};
        }

        setLoader(true);
        updateUser(id, result).then(res => {
            setSnack({open: true, text: res.messages});
            document.getElementById("password").value = "";
            document.getElementById("password_confirmation").value = "";
            setSelectUser({id: '', name: '', invitation_token: ''});
            setClearForm(true);
            handleSearch();
            setTimeout(() => {
                setClearForm(false);
            }, 0)
            fetchData();
            setLoader(false);
        }).catch(err => {
            setSnack({open: true, text: err.messages.error});
            setLoader(false);
        })
    }

    const hanleValidate = () => {
        let values = getValues();
        if(values.name === ""){
            setSnack({open: true, text: "請輸入真實姓名"});
            return false;
        }
        if(values.phone === ""){
            setSnack({open: true, text: "請輸入電話號碼"});
            return false;
        }
        if(values.password !== "" && values.password_confirmation === ""){
            setSnack({open: true, text: "請輸入確認密碼"});
            return false;
        }
        if(values.password_confirmation !== "" && values.password === ""){
            setSnack({open: true, text: "請輸入登入密碼"});
            return false;
        }
        if(values.password !== "" && values.password_confirmation !== ""){
            if(values.password_confirmation !== values.password){
                setSnack({open: true, text: "確認密碼不一致"});
                return false;
            }
        }
        return true;
    }

    return (
        <div className="tools-tags-create-page">
            <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-container">
                    <FormGroup label="會員姓名" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="name"
                                type="text"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入會員姓名"
                                defaultValue={data.name}
                                ref={register}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="電話號碼" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="phone"
                                type="tel"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入電話號碼"
                                defaultValue={data.phone}
                                ref={register}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="會員職稱" required>

                        {/* role_assign */}
                        <select
                            name="role_id"
                            value={data.role_id}
                            onChange={(e) => setData({...data, role_id: e.target.value})}
                            ref={register}
                        >
                            <option value="">請選擇會員職稱</option>
                            { roleList.map((item, index) => <option value={item.id} key={index}>{item.name}</option> )}
                        </select>
                        <div className="radio-box">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="role_assign"
                                        checked={data.role_assign ? true : false}
                                        value="1"
                                        color="primary"
                                        inputRef={register}
                                        onChange={(e) => setData({...data, role_assign: e.target.checked ? 1 : 0})}
                                    />
                                }
                                label="強制鎖定職稱"
                            />
                        </div>
                    </FormGroup>
                    <FormGroup label="登入密碼">
                        <InputGroup>
                            <input
                                id="password"
                                tabIndex="1"
                                name="password"
                                type={passwordShow ? "text" : "password"}
                                spellCheck="false"
                                autoComplete="new-password"
                                placeholder="若無需更改請留空"
                                ref={register}
                            />
                            <div className="input-text">
                                <button type="button" className="view-pass-btn" onClick={() => setPasswordShow(!passwordShow)}>
                                    {passwordShow ? (<i className="far fa-eye"></i>) : (<i className="far fa-eye-slash text-secondary"></i>)}
                                </button>
                            </div>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="確認密碼">
                        <InputGroup>
                            <input
                                id="password_confirmation"
                                tabIndex="1"
                                name="password_confirmation"
                                type={repasswordShow ? "text" : "password"}
                                spellCheck="false"
                                autoComplete="new-password"
                                placeholder="若無需更改請留空"
                                ref={register}
                            />
                            <div className="input-text">
                                <button type="button" className="view-pass-btn" onClick={() => setRepasswordShow(!repasswordShow)}>
                                    {repasswordShow ? (<i className="far fa-eye"></i>) : (<i className="far fa-eye-slash text-secondary"></i>)}
                                </button>
                            </div>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="邀請人帳號">
                        <div className="text-secondary font-size-ss mb-1">原邀請人：{ data.parent_phone } ({data.parent_name})</div>
                        <SearchFilter
                            placeholder="若無需更改請留空"
                            data={searchData}
                            callback={(value) => setSelectUser(value)}
                            clearForm={clearForm}
                        />
                    </FormGroup>
                    <FormGroup label="邀請人姓名">
                        <TextGroup>
                            { selectUser && (selectUser.name && selectUser.name !== "") ? selectUser.name : "( 系統自動帶入, 請輸入上方邀請人帳號 )" }
                        </TextGroup>
                    </FormGroup>
                    <FormGroup label="邀請碼">
                        <TextGroup>
                            { selectUser && (selectUser.invitation_token && selectUser.invitation_token !== "") ? selectUser.invitation_token : "( 系統自動帶入, 請輸入上方邀請人帳號 )" }
                        </TextGroup>
                    </FormGroup>
                </div>
                <div className="text-right">
                    <ConfirmButton
                        className="submit-btn"
                        onValidate={hanleValidate}
                        onSuccess={onSubmit}
                        confirmText="確定要送出嗎？"
                        confirm={{show: true, text: '送出'}}
                        cancel={{show: true}}
                    />
                </div>
            </form>
        </div>
    )
}
import React, { useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GlobalContext } from 'store/context/GlobalContext';
import useForm from "react-hook-form";
import { FormGroup, InputGroup, TextGroup } from 'components/FormGroup';
import { updateAdminProfile, fetchUser } from "services/authService";
import ConfirmButton from 'components/ConfirmButton';

function Profile() {
    const user = useSelector((state) => state.Auth.user);
    const dispatch = useDispatch();
    const context = useContext(GlobalContext);
    const { setSnack, setLoader } = context;
    const { register, handleSubmit, getValues, setValue, reset } = useForm();
    const [ oldPasswordShow, setOldPasswordShow ] = useState(false);
    const [ passwordShow, setPasswordShow ] = useState(false);
    const [ repasswordShow, setRepasswordShow ] = useState(false);

    const onSubmit = () => {
        let values = getValues();

        setLoader(true);
        updateAdminProfile(values).then(res => {
            setSnack({open: true, text: res.messages});
            setValue("old_password", "");
            setValue("password", "");
            setValue("password_confirmation", "");
            dispatch(fetchUser());
            setLoader(false);
        }).catch(err => {
            setSnack({open: true, text: err.messages.error});
            setLoader(false);
        })
    }

    const hanleValidate = () => {
        let values = getValues();
        
        if(values.password !== "" && values.old_password === ""){
            setSnack({open: true, text: "請輸入舊密碼"});
            return false;
        }
        if(values.password_confirmation !== "" && values.password === ""){
            setSnack({open: true, text: "請輸入新密碼"});
            return false;
        }
        if(values.password !== "" && values.password_confirmation !== ""){
            if(values.password_confirmation !== values.password){
                setSnack({open: true, text: "確認密碼不一致"});
                return false;
            }
        }
        if(values.name === ""){
            setSnack({open: true, text: "請輸入管理員暱稱"});
            return false;
        }

        return true;
    }

    const handleRole = (role) => {
        switch (role) {
            case "editor": return "編輯者";
            case "admin": return "管理員";
            case "super": return "總管理員";
            case "developer": return "系統開發者";
            default: return "-";
        }
    }

    return (
        <div className="tools-tags-create-page">
            <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-container">
                    <FormGroup label="管理員帳號">
                        <TextGroup>{user.username}</TextGroup>
                    </FormGroup>
                    <FormGroup label="舊密碼">
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="old_password"
                                type={oldPasswordShow ? "text" : "password"}
                                spellCheck="false"
                                autoComplete="new-password"
                                placeholder="若無需更改請留空"
                                ref={register}
                            />
                            <div className="input-text">
                                <button type="button" className="view-pass-btn" onClick={() => setOldPasswordShow(!oldPasswordShow)}>
                                    {oldPasswordShow ? (<i className="far fa-eye"></i>) : (<i className="far fa-eye-slash text-secondary"></i>)}
                                </button>
                            </div>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="新密碼">
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="password"
                                type={passwordShow ? "text" : "password"}
                                spellCheck="false"
                                autoComplete="new-password"
                                placeholder="若無需更改請留空"
                                ref={register}
                            />
                            <div className="input-text">
                                <button type="button" className="view-pass-btn" onClick={() => setPasswordShow(!passwordShow)}>
                                    {passwordShow ? (<i className="far fa-eye"></i>) : (<i className="far fa-eye-slash text-secondary"></i>)}
                                </button>
                            </div>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="確認新密碼">
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="password_confirmation"
                                type={repasswordShow ? "text" : "password"}
                                spellCheck="false"
                                autoComplete="new-password"
                                placeholder="若無需更改請留空"
                                ref={register}
                            />
                            <div className="input-text">
                                <button type="button" className="view-pass-btn" onClick={() => setRepasswordShow(!repasswordShow)}>
                                    {repasswordShow ? (<i className="far fa-eye"></i>) : (<i className="far fa-eye-slash text-secondary"></i>)}
                                </button>
                            </div>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="管理員暱稱" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="name"
                                type="text"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入管理員暱稱"
                                ref={register}
                                defaultValue={user.name}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="管理員權限">
                        <TextGroup>{handleRole(user.role)}</TextGroup>
                    </FormGroup>
                </div>
                <div className="text-right">
                    <ConfirmButton
                        className="submit-btn"
                        onValidate={hanleValidate}
                        onSuccess={onSubmit}
                        confirmText="確定要更改嗎？"
                        confirm={{show: true, text: '確定'}}
                        cancel={{show: true}}
                    />
                </div>
            </form>
        </div>
    )
}

export default Profile;
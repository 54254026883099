import React, { useState, useEffect, useContext } from 'react';
import Thead from './Thead';
import Tbody from './Tbody';
import Paginations from './Paginations';
import OnePageShow from './OnePageShow';
import SearchBar from './SearchBar';
import NoData from 'components/NoData';

const Table = (props) => {
    const { column, data, checkbox, searchbar, defaultPerPage, callback, checkboxArrayCallback } = props;
    const [ callbackParams, setCallbackParams ] = useState({countPerPage: defaultPerPage});
    const [ checkboxArray, setCheckboxArray ] = useState([]);

    useEffect(() => {
        checkboxArrayCallback && checkboxArrayCallback(checkboxArray);
    }, [checkboxArray])

    useEffect(() => {
        callback && callbackParams && callback(callbackParams);
    }, [callbackParams]);

    const handleCheckbox = item => {
        if(item.key === 'all') {
            if(item.toggle === "checked"){
                let checkboxKey = [];
                for (const key of data.data.keys()) checkboxKey.push(key);
                setCheckboxArray(checkboxKey);
                return;
            }
            if(item.toggle === "" || item.toggle === "indeterminate"){
                setCheckboxArray([]);
                return;
            }
        } else {
            let array = Array.from(checkboxArray);
            let index = array.indexOf(item.key);
            if(item.toggle && index < 0) {
                array.push(item.key);
                setCheckboxArray(array);
            } else {
                if (index > -1) array.splice(index, 1);
                setCheckboxArray(array);
            }
        }
    }

    return (<>
        <div className="table-head d-flex justify-content-between align-items-center flex-wrap sticky-fit-width sticky-left">
            { searchbar && <SearchBar searchCallback={(searchResult) => setCallbackParams({...callbackParams, search: searchResult, page: 1})} searchbar={searchbar} /> }
            { defaultPerPage && <OnePageShow perPageCallback={(page) => setCallbackParams({...callbackParams, countPerPage: page})} defaultPerPage={defaultPerPage ? defaultPerPage : 10} />}
        </div>
        <div className="data-table">
            {
                data.data && data.data.length > 0 ? (
                    <table className="table">
                        <Thead
                            column={column}
                            data={data.data}
                            checkbox={checkbox}
                            checkboxArray={checkboxArray}
                            checkboxCallback={(e) => handleCheckbox({key: 'all', toggle: e})}
                            orderByCallback={(e) => setCallbackParams({...callbackParams, orderBy: e})}
                        />
    
                        <Tbody
                            column={column}
                            data={data.data}
                            checkbox={checkbox}
                            checkboxArray={checkboxArray}
                            checkboxCallback={e => handleCheckbox(e)}
                        />
                    </table>
                ) : <div style={{borderRadius: "5px", backgroundColor: "rgba(0,0,0,0.03)"}}><NoData /></div>
            }
        </div>
        <Paginations {...data} pageCallback={(e) => setCallbackParams({...callbackParams, page: e})}/>
    </>)
}

export default Table;
import React, { useRef } from "react";
import { useLocation } from 'react-router-dom';
import { TransitionGroup, Transition } from "react-transition-group";

const TIMEOUT = 350;

const getTransitionStyles = {
	entering: {
		position: `absolute`,
		opacity: 0,
	},
	entered: {
		transition: `opacity ${TIMEOUT}ms ease-in-out`,
		opacity: 1,
	},
	exiting: {
		transition: `opacity ${TIMEOUT}ms ease-in-out`,
		opacity: 0,
	},
}

const TransitionGroupFade = ({ children }) => {
	const location = useLocation();
	const nodeRef = useRef(null);

	return (
		<TransitionGroup>
			<Transition
				key={location.pathname}
				timeout={{
					enter: TIMEOUT,
					exit: TIMEOUT,
				}}
				nodeRef={nodeRef}
			>
				{status => (
					<div style={{...getTransitionStyles[status]}} ref={nodeRef}>
						{children}
					</div>
				)}
			</Transition>
		</TransitionGroup>
	)
}

  
export default TransitionGroupFade;
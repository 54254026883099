import React, { useState, useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { TableContainer, Table, TableTitle } from 'components/FormTables';
import AddIcon from '@mui/icons-material/Add';
import ConfirmButton from 'components/ConfirmButton';
import SpeedDialMenu from 'components/SpeedDialMenu';
import { getAdminList, deleteAdmin } from 'services/authService';
import { GlobalContext } from 'store/context/GlobalContext';

export default function Page() {
    const user = useSelector((state) => state.Auth.user);
    const context = useContext(GlobalContext);
    const { setSnack, setLoader } = context;
    const [ data, setData ] = useState({});
    const [ params, setParams ] = useState();

    const column = [
        {
            key: 'id',
            name: "#",
        },
        {
            key: 'name',
            name: "管理員暱稱",
        },
        {
            key: 'username',
            name: "帳號",
        },
        {
            key: 'role',
            name: "管理員權限",
            view: (data) => {
                switch (data.role){
                    case "editor": return "編輯者";
                    case "admin": return "管理員";
                    case "super": return "總管理員";
                    case "developer": return "系統開發者";
                    default: return "-";
                }
            }
        },
        {
            key: 'tools',
            name: '功能',
            view: (data) => (<>
                <div className="d-flex justify-content-center">
                    {
                        user.role === 'super' || user.role === "developer" ? (<>
                            <Link to={`/system/Administrator/${user.username === data.username ? "profile" : `edit/${data.id}`}`} className="common-btn table-btn black"><i className="fas fa-pen mr-1"></i>修改</Link>
                            {
                                user.username !== data.username &&
                                <ConfirmButton
                                    className="common-btn table-btn red ml-2"
                                    onSuccess={() => handleDelete(data.id)}
                                    confirmText="確定要刪除嗎？"
                                    confirm={{show: true, text: '刪除'}}
                                    cancel={{show: true}}
                                >
                                    <i className="fas fa-trash mr-1"></i>刪除
                                </ConfirmButton>
                            }
                        </>) : user.username === data.username ? <Link to={`/system/Administrator/profile`} className="common-btn table-btn black"><i className="fas fa-pen mr-1"></i>修改</Link> : "-"
                    }
                </div>
            </>)
        },
    ];

    const handleDelete = (id) => {
        setLoader(true);
        deleteAdmin(id).then(res => {
            setSnack({open: true, text: res.messages});
            fetchData();
            setLoader(false);
        }).catch(err => {
            setSnack({open: true, text: err.messages.error});
            setLoader(false);
        })
    }

    const fetchData = useCallback(async (callbackParams) => {
        setLoader(true);
        let result = await getAdminList(callbackParams ? callbackParams : params);
        setData(result);
        // setData({})
        setLoader(false);
    }, []);

    const handleCallback = (callbackParams) => {
        setParams(callbackParams);
        fetchData(callbackParams);
    }

    const actions = [
        { icon: <Link to={`/system/Administrator/create`} className="d-flex justify-content-center align-items-center" style={{width: "40px", height: "40px"}}><AddIcon /></Link>, name: '新增管理員'},
    ];

    return (<>
        <TableContainer>
            <Table
                column={column}
                data={data}
                checkbox={false}
                searchbar={{date: false, keyword: true}}
                defaultPerPage={10}
                callback={(callbackParams) => handleCallback(callbackParams)}
            />
        </TableContainer>
        { (user.role === "super" || user.role === "developer") && <SpeedDialMenu menuList={actions} /> }
    </>)
}
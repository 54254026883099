import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import { TableContainer, Table, TableTitle } from 'components/FormTables';
import { CSVLink } from "react-csv";
import moment from 'moment/moment.js';
import AddIcon from '@mui/icons-material/Add';
import GetAppIcon from '@mui/icons-material/GetApp';

import { userEmailList } from 'services/authService';
import SpeedDialMenu from 'components/SpeedDialMenu';
import { GlobalContext } from 'store/context/GlobalContext';

export default function Page() {
    const context = useContext(GlobalContext);
    const { setSnack, setLoader } = context;
    const [ data, setData ] = useState({});
    const [ params, setParams ] = useState();
    const [ exportData, setExportData ] = useState([]);

    useEffect(() => {
        if(data.data !== undefined) {
            let result = [];
            data.data.map((item, key) => {
                result.push(
                    {
                        "#": item.id,
                        "會員帳號": item.phone,
                        "會員姓名": item.name,
                        "信箱驗證": handleStatus(item.email_verified),
                        "信箱": item.email,
                        "更新時間": moment(item.updated_at).format("YYYY-MM-DD HH:mm:ss"),
                    }
                )
            })
            setExportData(result);
        }
    }, [data])

    const handleStatus = (status) => {
        switch (status) {
            case 0: return "未驗證";
            case 1: return "已驗證";
            default: return;
        }
    }

    const column = [
        {
            key: 'id',
            name: "#",
        },
        {
            key: 'phone',
            name: "會員帳號",
        },
        {
            key: 'name',
            name: "會員姓名",
        },
        {
            key: 'email_verified',
            name: "信箱驗證",
            view: (data) => {
                switch(data.email_verified) {
                    case "unverified": return <span className="text-secondary">未驗證</span>;
                    case "verified": return <span className="text-success">已驗證</span>;
                    default: return;
                }
            }
        },
        {
            key: 'email',
            name: "信箱",
            view: (data) => data.email ? data.email : "-"
        },
        {
            key: 'updated_at',
            name: "更新時間",
            view: (data) => {
                return moment(data.updated_at).format("YYYY-MM-DD HH:mm:ss");
            }
        },
        {
            key: 'tools',
            name: '功能',
            view: (data) => {
                return (
                    <div className="d-flex justify-content-center">
                        <Link to={`/member/Mail/edit/${data.id}`} className="common-btn table-btn black"><i className="fas fa-pen mr-1"></i>修改</Link>
                    </div>
                )
            }
        },
    ];

    const fetchData = useCallback(async (callbackParams) => {
        setLoader(true);
        // let result = await userEmailList(callbackParams ? callbackParams : params);
        // setData(result);
        setData({});
        setLoader(false);
    }, []);

    const handleCallback = (callbackParams) => {
        setParams(callbackParams);
        fetchData(callbackParams);
    }

    const actions = [
        { icon: <CSVLink filename="社群帳號綁定列表.csv" data={exportData} className="d-flex justify-content-center align-items-center" style={{width: "40px", height: "40px"}}><GetAppIcon /></CSVLink>, name: '匯出資料'},
    ];

    return (<>
        <TableContainer>
            <Table
                column={column}
                data={data}
                checkbox={false}
                searchbar={{date: false, keyword: true}}
                defaultPerPage={10}
                callback={(callbackParams) => handleCallback(callbackParams)}
            />
        </TableContainer>
        {/* <SpeedDialMenu menuList={actions} /> */}
    </>)
}
const navigation = [
    {
        name: '儀表板',
        url: '/dashboard',
        icon: 'fal fa-chart-line',
        main: true,
    },
    {
        name: '系統管理',
        url: '/system',
        icon: '',
        menuOpen: true,
        children: [
            {
                name: '管理員列表',
                url: '/Administrator',
                icon: 'fas fa-user-shield',
                main: true,
                children: [
                    {
                        name: '新增管理員',
                        url: '/create',
                        role: ['super', 'developer'],
                    },
                    {
                        name: '編輯管理員',
                        url: '/edit',
                        role: ['super', 'developer'],
                    },
                    {
                        name: '編輯管理員',
                        url: '/profile',
                    },
                ]
            },
        ],
    },
    {
        name: '會員管理',
        url: '/member',
        icon: '',
        menuOpen: true,
        children: [
            {
                name: '會員列表',
                url: '/List',
                icon: 'fas fa-users',
                main: true,
                children: [
                    {
                        name: '編輯會員資料',
                        url: '/edit',
                    },
                    {
                        name: '新增會員',
                        url: '/create',
                    },
                ]
            },
            {
                name: '信箱綁定',
                url: '/Mail',
                icon: 'fas fa-envelope',
                main: true,
            },
            {
                name: '社群帳號綁定',
                url: '/Social',
                icon: 'fas fa-share-square',
                main: true,
            },
        ],
    },
    {
        name: '方案管理',
        url: '/plan',
        icon: '',
        menuOpen: true,
        children: [
            {
                name: '會員方案列表',
                url: '/List',
                icon: 'fas fa-star',
                main: true,
            },
            {
                name: '方案購買紀錄',
                url: '/Purchase',
                icon: 'fas fa-shopping-cart',
                main: true,
            },
            {
                name: '取消續訂紀錄',
                url: '/Unsubscribe',
                icon: 'fas fa-ban',
                main: true,
            },
            {
                name: '退款申請',
                url: '/Refund',
                icon: 'fas fa-badge-dollar',
                main: true,
            },
        ],
    },
    {
        name: '社群管理',
        url: '/social',
        icon: '',
        menuOpen: true,
        children: [
            {
                name: '聯絡我們',
                url: '/Contact',
                icon: 'fas fa-comment-alt-lines',
                main: true,
            },
        ],
    },
    {
        name: '常見問題管理',
        url: '/faq',
        icon: '',
        menuOpen: true,
        children: [
            {
                name: '常見問題列表',
                url: '/List',
                icon: 'fas fa-question',
                main: true,
            },
            {
                name: '常見問題分類',
                url: '/Category',
                icon: 'fas fa-question-circle',
                main: true,
            },
        ],
    },
    {
        name: '應用管理',
        url: '/application',
        icon: '',
        menuOpen: true,
        children: [
            {
                name: '最新公告',
                url: '/News',
                icon: 'fas fa-bullhorn',
                main: true,
            },
            {
                name: '推播通知',
                url: '/Notification',
                icon: 'fas fa-bell',
                main: true,
            },
        ],
    },
]


export default navigation;
import React, { useState, useEffect, useContext, useRef } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { GlobalContext } from 'store/context/GlobalContext';
import Collapse from '@mui/material/Collapse';

const Thead = ({ column, data, checkbox, checkboxArray, checkboxCallback, orderByCallback }) => {
    const [orderBy, setOrderBy] = useState({order: -1, sort: 'desc'});
    const [ checkboxStatus, setCheckboxStatus ] = useState("");
    const theadRef = useRef(null);
    const mainRef = document.getElementsByTagName('main')[0];
    const [ shadow, setShadow ] = useState(false);

    useEffect(() => {
        if(mainRef) {
            mainRef.onscroll = () => {
                let refViewportOffset = theadRef.current.getBoundingClientRect();
                if(refViewportOffset.top <= 60){
                    setShadow(true);
                }else{
                    setShadow(false);
                }
            };
        }
    }, [mainRef])

    useEffect(() => {
        if(checkboxArray){
            if(checkboxArray.length > 0 && checkboxArray.length < data.length){
                setCheckboxStatus("indeterminate");
                return;
            }
            if(checkboxArray.length === data.length){
                setCheckboxStatus("checked");
                return;
            }
            if(checkboxArray.length === 0){
                setCheckboxStatus("");
                return;
            }
        }
    }, [checkboxArray])

    const handleOrderByChange = (key, item) => {
        let data = {order: item.key};
        if(orderBy.order !== item.key) {
            data = {...data, sort: 'desc'}
        } else {
            data = {...data, sort: orderBy.sort === 'asc' ? 'desc' : 'asc'}
        }
        setOrderBy(data);
        orderByCallback(data);
    }

    return (
        <thead className="sticky-top" style={{boxShadow: shadow ? "0 1px 3px 0 rgba(0,0,0,0.1)" : "none"}} ref={theadRef}>
            <tr>
                <th colSpan="100%" className="pd-0" style={{borderBottom: "0"}}>
                    <div className="sticky-fit-width sticky-top sticky-left">
                        <Collapse
                            in={checkboxArray.length > 0}
                            timeout={300}
                            className='abc'
                            unmountOnExit
                        >
                            <div className="batch-bar">
                                <div className="d-flex align-items-center">
                                    <div className="font-size-16">批次處理</div>
                                    <div className="font-size-12 mgl-3">已選取 {checkboxArray.length} 個</div>
                                </div>
                                <div className="d-flex align-items-center">{checkbox.tools}</div>
                            </div>
                        </Collapse>
                    </div>

                </th>
            </tr>
            { column && (
                <tr>
                    { checkbox.open && (
                        <th className="table-border table-border-right sticky-top sticky-left" style={{minWidth: '70px', width: '70px', zIndex: "3"}}>
                            <Checkbox
                                sx={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}
                                onChange={() => checkboxCallback(checkboxStatus === "" ? "checked" : "")}
                                indeterminate={checkboxStatus === "indeterminate"}
                                checked={checkboxStatus === "checked"}
                            />
                        </th>
                    ) }
                    {column.map((item, key) => {
                        return (
                            item.order ? (
                                <th className="order" key={key} onClick={() => handleOrderByChange(key, item)}>
                                    {item.name}
                                    <i className={`fas fa-sort-up ${orderBy.order === item.key && orderBy.sort === 'asc' && 'text-dark'}`}></i>
                                    <i className={`fas fa-sort-down ${orderBy.order === item.key && orderBy.sort === 'desc' && 'text-dark'}`}></i>
                                </th>
                            ) : <th key={key}>{item.name}</th>
                        )
                    })}
                </tr>
            )}
        </thead>
    );
}

export default Thead;
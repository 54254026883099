import React from 'react';

const FormGroup = (props) => {
    const { label, required, children } = props;

    return (
        <div className="form-group">
            <div className="label-group">
                <label>{ label }</label>
                { required && <div className="text-secondary font-size-sm">( 必填項目 )</div> }
            </div>
            <div style={{flex: "1", width: "0", position: "relative"}}>
                { children }
            </div>
        </div>
    )
}

const InputGroup = (props) => {
    const { desc, children, className, style } = props;

    return(
        <div className={`input-group ${className ? className : ""}`} style={style}>
            { children }
            { desc && <div className="input-desc">{ desc }</div> }
        </div>
    )
}

const TextGroup = ({ children }) => {
    return(
        <div className="text-group">
            { children }
        </div>
    )
}

const SwitchGroup = ({ children }) => {
    return(
        <div className="switch-group">
            { children }
        </div>
    )
}

export { FormGroup, InputGroup, TextGroup, SwitchGroup };
import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PrivateLayout from './Private';
import PublicLayout from './Public';

function Layout(props) {
    const isAuthenticated = useSelector((state) => state.Auth.isAuthenticated);
    const { children } = props;

    if (isAuthenticated) {
        return <PrivateLayout {...props}>{children}</PrivateLayout>
    }
    return <PublicLayout {...props}>{children}</PublicLayout>
}
  
export default withRouter(Layout);
import React, { useState, useEffect } from 'react';
import { InputGroup } from 'components/FormGroup';
import Autocomplete from '@mui/material/Autocomplete';

const SearchFilter = (props) => {
    const { placeholder, data, callback, nullInputValue, clearForm, disableCloseOnSelect, noOptionsText, disablePortal } = props;
	const [ value, setValue ] = useState(null);
    const [inputValue, setInputValue] = useState('');

	const handleChange = (event, newValue) => {
        if(nullInputValue) {
            setValue(null)
            setInputValue("")
        }else{
            setValue(newValue);
        }
		callback(newValue);
	}

    useEffect(() => {
        if(clearForm) setValue(null);
    }, [clearForm])

    return (
        <Autocomplete
            options={data}
            getOptionLabel={(option) => option.lable}
			noOptionsText={noOptionsText ? noOptionsText : "查無資料"}
			value={value}
			onChange={handleChange}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
            renderInput={(params) => {
                return (<>
                    <InputGroup>
						<div className="w-100" ref={params.InputProps.ref}>
							<input
								type="text"
								placeholder={placeholder && placeholder}
								{...params.inputProps}
							/>
						</div>
                    </InputGroup>
                </>)
            }}
            disableCloseOnSelect={disableCloseOnSelect}
            disablePortal={disablePortal}
        />
    )
}

export default SearchFilter;